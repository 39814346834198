
/* Add your profile image here */
.Photo{
    background-image: url('https://images.pexels.com/photos/3799821/pexels-photo-3799821.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
}
/* Projects Photo */
.project1{ 
    background-image: url('../../public/GoalsKeeper.PNG');    
}
.project2{
    background-image: url("../../public/taskapp.PNG");
}
.project3{
    background-image: url("../../public/tennis_ball.png");
}
.project4{
    background-image: url('../../public/thestudiologo.PNG');
}
.project5{
    background-image: url('../../public/mysite.PNG');
}
.project7{
    background-image: url('../../public/TribecaMortgage.PNG');
}
.project6{
    background-image: url('../../public/Swift.PNG');
}
.project8{
    background-image: url('../../public/age-to-min.PNG');
}
/* Miscelaneous Activities photo */
.misc1{
    background-image: url('https://via.placeholder.com/400x600.png?text=Miscellaneous+one');
}
.misc2{
    background-image: url('https://via.placeholder.com/400x600.png?text=Miscellaneous+two');
}
.misc3{
    background-image: url('https://via.placeholder.com/400x600.png?text=Miscellaneous+three');
}